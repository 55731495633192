

.dash-card1 {
    background-color: #4FC9DA;
    color: #fff !important;
}
.dash-card2 {
    background-color: #B8D935 !important;
    color: white !important;
}


.dash-card3 {
    background-color: #DB5437 !important;
    color: white !important;
}

.dash-card4 {
    background-color: #2196F3;
    color: white !important;
}

.card {
    transition: transform 0.2s;
    cursor: pointer;
}

.dash-card1:hover,.dash-card2:hover,.dash-card3:hover,.dash-card4:hover {
    transform: scale(1.05);
}

.card-body a {
    text-decoration: none;
    color: inherit;
}